import { MutableRefObject, useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { useTitle } from 'hooks';
import { useRedux, useReduxDispatch, useReduxSelector } from 'redux/store.hooks';
import { LabellingMode, LabelStudioAnnotation, Image, ImageType } from 'redux/annotations/annotations.type';
import { PageWrapper } from 'components/containers';
import { Toggle } from 'components/shared';
import { ImageDetailBody } from './components/image-detail.body';
import { ImageDetailFooter } from './components/image-detail.footer';
import { EditDamageButton } from './components/edit-damage';
import { useAnnotations } from './hooks/useAnnotations';
import { useImageNavigation } from './hooks/useImageNavigation';
import { transition } from 'components/shared/skeleton-ui/skeleton-ui.component';
import LabellingOptionSelection, { LabellingOption } from './components/labelling-option-selection';
import ImageSelection from './components/image-selection';
import { ImageDetailAlerts } from './components/image-detail-alerts';
import { ImageFile } from 'redux/images/images.type';
import { InAnalysisRobot } from '../../components/annotation-in-analysis';
import { useCanEditCurrentImage } from './hooks/useCanEditCurrentImage';
import { Alert } from 'react-bootstrap';
import { DamageLabelShape } from './types/damage-label-shape';
import { retrieveUserData } from 'services';
import { AnchorThumbnail } from 'components/shared/image-thumbnail/image-thumbnail';

const EditDamageWrapper = styled.div`
  margin-right: 1rem;
`;

const Skeleton = styled.div`
  min-height: 80vh;
  animation: ${transition} 1.5s infinite;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ImageDetailHeader = styled.div`
  margin-bottom: 1rem;
  display: flex;
  justify-content: space-between;

  & .image-selection {
    width: 370px;
    display: flex;
    justify-content: flex-end;
  }
`;

const InAnalysisState = styled.span`
  padding-top: 1rem;
  font-weight: 500;
`;

const InAnalysisWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

interface InAnalysisProps {
  photoSeriesId: string | undefined,
  imageId: string | undefined,
  labelStudioRef: any,
  imageFile: ImageFile,
  labellingMode: LabellingMode,
  handleSubmitAnnotation: (
    areas:any,
    imageFile: ImageFile,
    confirm: boolean | undefined,
    onConfirm: () => void, onSave: () => void,
    labelStudioRef: any
  ) => void,
}

const POLLING_INTERVAL_IN_SECONDS = 25;

const InAnalysis = (props: InAnalysisProps) => {
  const { photoSeriesId, labelStudioRef, imageFile, imageId, handleSubmitAnnotation, labellingMode } = props;
  const {
    annotations: { actions: annotationsActions }
  } = useRedux();
  const dispatch = useReduxDispatch();

  const checkAnalyzis = useCallback(() => {
    dispatch(annotationsActions.getPhotoSeriesAnnotations({
      photoSeriesId: photoSeriesId as string, imageId, labellingMode,
    }));
  }, [imageId, photoSeriesId, labellingMode, dispatch, annotationsActions]);

  useEffect(() => {
    const timer = setTimeout(() => checkAnalyzis(), 1200);
    const interval = setInterval(() => checkAnalyzis(), POLLING_INTERVAL_IN_SECONDS * 1000);
    return () => {
      clearInterval(interval);
      clearTimeout(timer);
    };
  }, [checkAnalyzis]);

  return (
    <>
      <Alert variant="warning">
        <p><b>Wow, you&apos;re so fast!</b></p>
        DriveX robo inspector is currently analyzing this image for body parts and damages.<br />
        Results will be ready for review soon.
      </Alert>
      <Skeleton>
        <InAnalysisWrapper>
          <InAnalysisRobot width="7rem" />
          <InAnalysisState>
            Please wait. Analyzing image ...
          </InAnalysisState>
        </InAnalysisWrapper>
      </Skeleton>
      <ImageDetailFooter
        photoSeriesId={photoSeriesId}
        imageId={imageId}
        labelStudioRef={labelStudioRef}
        imageFile={imageFile}
        handleSubmitAnnotation={handleSubmitAnnotation}
        labellingMode={labellingMode}
      />
    </>
  );
};

const AdditionalImagesWrapper = styled.div`
  display: flex;
  margin-bottom: 1rem;
`;

const ImageThumbnailWrapper = styled.div`
  margin-top: 0.5rem;
  margin-right: 0.75rem;
`;

interface AdditionalImagesProps {
  additionalImages: Image[] | undefined,
  currentImageId: string | undefined,
  photoSeriesId: string | undefined
}

const AdditionalImages = (props: AdditionalImagesProps) => {
  const { additionalImages, currentImageId, photoSeriesId } = props;
  if (!additionalImages) return null;

  const currentImageAdditionalImages = additionalImages.filter((image: Image) => image.relatedImageId === currentImageId);
  if (!currentImageAdditionalImages || currentImageAdditionalImages.length === 0) return null;

  return (
    <div>
      <h5>Additional images</h5>
      <AdditionalImagesWrapper>
        {
          currentImageAdditionalImages.map((image: Image) => (
            <ImageThumbnailWrapper key={image.id}>
              <AnchorThumbnail
                filePath={image.filePath}
                fileName={image.fileName}
                width="small"
                href={`/labelling/photoSeries/${photoSeriesId}/image/${image.id}/mode/${LabellingMode.DamageDetection}`}
                target="_blank"
              />
            </ImageThumbnailWrapper>
          ))
        }
      </AdditionalImagesWrapper>
    </div>
  );
};

interface ImageDetailContentProps {
  image: Image;
  setLabelStudioRef: (value: MutableRefObject<any> | null) => void,
  setLabellingOption: (value: LabellingOption) => void,
  imageIndex: number,
  damageLabels: string,
  bodyPartLabels: string,
  damageAnnotations: LabelStudioAnnotation[] | null,
  bodyPartAnnotations: LabelStudioAnnotation[] | null,
  imageFile: ImageFile,
  photoSeriesId: string | undefined,
  imageId: string | undefined,
  labellingOption: LabellingOption,
  labellingMode: LabellingMode,
  handleSubmitAnnotation: (
    areas:any,
    imageFile: ImageFile,
    confirm: boolean | undefined,
    onConfirm: () => void, onSave: () => void,
    labelStudioRef: any
  ) => void,
  labelStudioRef: any,
  images: Image[] | undefined,
  damageLabelShape: DamageLabelShape,
  subMaskLabels: string,
  subMaskAnnotations: LabelStudioAnnotation[] | null
}

const ImageDetailContent = (props: ImageDetailContentProps) => {
  const {
    setLabelStudioRef, imageIndex, damageLabels, bodyPartLabels, damageAnnotations, bodyPartAnnotations,
    imageFile, photoSeriesId, imageId, labellingOption, labellingMode, setLabellingOption, handleSubmitAnnotation,
    labelStudioRef, images, damageLabelShape, subMaskLabels, subMaskAnnotations, image
  } = props;

  if (!images) return null;

  const additionalImages = images
    ?.filter((image: Image) => image.imageType === ImageType.Additional);

  const imagesForLabelling = image.imageType === ImageType.Additional
    ? additionalImages
    : images?.filter((image: Image) => image.imageType !== ImageType.Additional);

  const { analysedAt, id } = imagesForLabelling[imageIndex];

  if (image.imageType === ImageType.Additional) {
    return (
      <ImageDetailBody
        imageIndex={imageIndex}
        damageLabels={damageLabels}
        bodyPartLabels={bodyPartLabels}
        damageAnnotations={damageAnnotations}
        bodyPartAnnotations={bodyPartAnnotations}
        imageFile={imageFile}
        photoSeriesId={photoSeriesId}
        imageId={imageId}
        setLabelStudioRef={setLabelStudioRef}
        labellingOption={LabellingOption.All}
        labellingMode={LabellingMode.DamageDetection}
        damageLabelShape={damageLabelShape}
        subMaskLabels={subMaskLabels}
        subMaskAnnotations={subMaskAnnotations}
      />
    );
  }

  if (!analysedAt) {
    return (
      <InAnalysis
        photoSeriesId={photoSeriesId}
        labelStudioRef={labelStudioRef}
        imageFile={imageFile}
        handleSubmitAnnotation={handleSubmitAnnotation}
        labellingMode={labellingMode}
        imageId={imageId}
      />
    );
  }

  return (
    <>
      <ImageDetailAlerts
        photoSeriesId={photoSeriesId}
        labellingMode={labellingMode}
      />
      <AdditionalImages
        additionalImages={additionalImages}
        currentImageId={id}
        photoSeriesId={photoSeriesId}
      />
      <ImageDetailHeader>
        <div className="labelling">
          <LabellingOptionSelection
            labellingOption={labellingOption}
            setLabellingOption={setLabellingOption}
            handleSubmitAnnotation={handleSubmitAnnotation}
            imageFile={imageFile}
            labelStudioRef={labelStudioRef}
            photoSeriesId={photoSeriesId}
            imageId={imageId}
            labellingMode={labellingMode}
          />
        </div>
        <div className="image-selection">
          <EditDamageWrapper>
            <EditDamageButton
              labelStudioRef={labelStudioRef}
              photoSeriesId={photoSeriesId}
              labellingMode={labellingMode}
              imageId={imageId}
            />
          </EditDamageWrapper>
          <ImageSelection
            photoSeriesId={photoSeriesId}
            imageIndex={imageIndex}
            images={imagesForLabelling}
            labelStudioRef={labelStudioRef}
            handleSubmitAnnotation={handleSubmitAnnotation}
            imageFile={imageFile}
            labellingMode={labellingMode}
          />
        </div>
      </ImageDetailHeader>
      <ImageDetailBody
        imageIndex={imageIndex}
        damageLabels={damageLabels}
        bodyPartLabels={bodyPartLabels}
        damageAnnotations={damageAnnotations}
        bodyPartAnnotations={bodyPartAnnotations}
        imageFile={imageFile}
        photoSeriesId={photoSeriesId}
        imageId={imageId}
        setLabelStudioRef={setLabelStudioRef}
        labellingOption={labellingOption}
        labellingMode={labellingMode}
        damageLabelShape={damageLabelShape}
        subMaskLabels={subMaskLabels}
        subMaskAnnotations={subMaskAnnotations}
      />
      <ImageDetailFooter
        photoSeriesId={photoSeriesId}
        imageId={imageId}
        labelStudioRef={labelStudioRef}
        imageFile={imageFile}
        handleSubmitAnnotation={handleSubmitAnnotation}
        labellingMode={labellingMode}
      />
    </>
  );
};

interface TitileActionsProps {
  photoSeriesId: string | undefined,
  imageId: string | undefined,
  labellingMode: LabellingMode,
  setLabellingMode: (value: LabellingMode) => void
}

const TitleActions = (props: TitileActionsProps) => {
  const { labellingMode, setLabellingMode, photoSeriesId, imageId } = props;
  const canEditCurrentImage = useCanEditCurrentImage(photoSeriesId, imageId, labellingMode);
  const useData = retrieveUserData();

  if (!canEditCurrentImage()) return null;

  const canEditTrainingAnnotations = useData.permissions.includes(21);
  if (!canEditTrainingAnnotations) return null;

  return (
    <>
      <span style={{ paddingRight: '1rem' }}>
        Training mode
      </span>
      <Toggle
        on={labellingMode === LabellingMode.ModelTraining}
        disabled={false}
        onClick={() => {
          const newLabellingMode = labellingMode == LabellingMode.ModelTraining
            ? LabellingMode.DamageDetection
            : LabellingMode.ModelTraining;

          setLabellingMode(newLabellingMode);
        }}
      />
    </>
  );
};

function ImageDetail() {
  useTitle('Image Detail');

  const { photoSeriesId, imageId } = useParams();
  const [isLoadingAnnotations, setIsLoadingAnnotations] = useState(true);
  const [isLoadingImage, setIsLoadingImage] = useState(true);
  const [labellingOption, setLabellingOption] = useState(LabellingOption.All);
  const [labelStudioRef, setLabelStudioRef] = useState<MutableRefObject<any> | null>(null);

  const { imageIndex } = useImageNavigation();

  const {
    imageFile,
    damageLabels,
    bodyPartLabels,
    damageAnnotations,
    bodyPartAnnotations,
    handleSubmitAnnotation,
    labellingMode,
    setLabellingMode,
    damageLabelShape,
    subMaskLabels,
    subMaskAnnotations
  } = useAnnotations();

  const dispatch = useReduxDispatch();
  const {
    annotations: { actions: annotationsActions, selectors: annotationsSelectors },
    images: { actions: imagesActions },
    reviews: { actions: reviewActions }
  } = useRedux();

  const images = useReduxSelector((state) => annotationsSelectors.selectImagesByPhotoSeriesId(state, photoSeriesId as string));
  const image = useReduxSelector((state) =>
    annotationsSelectors.selectImageByImageIdAndPhotoSeriesId(state, photoSeriesId as string, imageId as string));

  const photoSeries = useReduxSelector((state) => annotationsSelectors.selectPhotoSeriesByPhotoSeriesId(state, photoSeriesId as string));

  useEffect(() => {
    if (photoSeries && photoSeries.windshieldRepairReplaceDecisionEnabled) {
      setLabellingOption(LabellingOption.Damage);
    }
  }, [photoSeries]);

  useEffect(() => {
    if (!images && photoSeriesId) {
      dispatch(annotationsActions.getPagedPhotoSeries({ pagedQuery: {
        page: 1
      },
      photoSeriesIds: [photoSeriesId] }));
    }
  }, [images, photoSeriesId, dispatch, annotationsActions]);

  useEffect(() => {
    setIsLoadingAnnotations(true);

    if (labellingMode === LabellingMode.LabelReview) {
      dispatch(reviewActions.getLabelReviewAnnotations({
        photoSeriesId: photoSeriesId as string
      })).then(() => setIsLoadingAnnotations(false));
    } else {
      dispatch(annotationsActions.getPhotoSeriesAnnotations({
        photoSeriesId: photoSeriesId as string,
        labellingMode,
      })).then(() => setIsLoadingAnnotations(false));
    }
  }, [photoSeriesId, dispatch, annotationsActions, labellingMode, reviewActions]);

  useEffect(() => {
    setIsLoadingImage(true);
    dispatch(imagesActions.downloadImageFile({ imageId: imageId as string }))
      .then(() => setIsLoadingImage(false));
  }, [imageId, dispatch, imagesActions]);

  const imagesForLabelling = images
    ?.filter((image: Image) => image.imageType !== ImageType.Additional);

  return (
    <PageWrapper
      full
      pageTitle={imagesForLabelling ? `Image Detail (${imageIndex + 1} of ${imagesForLabelling.length})` : 'Image Detail'}
      titleActions={
        <TitleActions
          labellingMode={labellingMode}
          setLabellingMode={setLabellingMode}
          photoSeriesId={photoSeriesId}
          imageId={imageId}
        />
      }
    >
      {
        isLoadingAnnotations || isLoadingImage || image === undefined
          ? (
            <div className="border">
              <Skeleton />
            </div>
          )
          : (
            <ImageDetailContent
              image={image}
              imageIndex={imageIndex}
              damageLabels={damageLabels}
              bodyPartLabels={bodyPartLabels}
              damageAnnotations={damageAnnotations}
              bodyPartAnnotations={bodyPartAnnotations}
              imageFile={imageFile}
              photoSeriesId={photoSeriesId}
              imageId={imageId}
              setLabelStudioRef={setLabelStudioRef}
              labellingOption={labellingOption}
              labellingMode={labellingMode}
              handleSubmitAnnotation={handleSubmitAnnotation}
              labelStudioRef={labelStudioRef}
              setLabellingOption={setLabellingOption}
              images={images}
              damageLabelShape={damageLabelShape}
              subMaskLabels={subMaskLabels}
              subMaskAnnotations={subMaskAnnotations}
            />
          )
      }
    </PageWrapper>
  );
}

export default ImageDetail;
