import { Form } from 'react-bootstrap';

type AutoSubmitCheckboxProps = {
  value: boolean,
  setValue: (value: boolean) => void,
  label: string,
}

const AutoSubmitCheckbox = (props: AutoSubmitCheckboxProps) => {
  const { value, setValue, label } = props;
  return (
    <Form>
      <Form.Check
        type="checkbox"
        label={label}
        onChange={(event: any) => setValue(event.target.checked)}
        checked={value}
      />
    </Form>
  );
};

export { AutoSubmitCheckbox };
