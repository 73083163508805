import { useEffect, useState, useCallback } from 'react';
import { Modal, Button, Alert, Form, FloatingLabel } from 'react-bootstrap';
import { LabelStudioRegion, LabelStudioArea, BodyPart, LabellingMode, RepairDecision, DamageType } from 'redux/annotations/annotations.type';
import { bboxIntersects } from 'utils/bounding-box';
import { RelatedDamage } from './related-damage';
import { useFindRelatedDamages } from '../hooks/useFindRelatedDamages';
import { useCanEditCurrentImage } from '../hooks/useCanEditCurrentImage';
import { useReduxSelector, useRedux } from 'redux/store.hooks';
import { updateDamageRepairDecision } from 'redux/annotations/annotations.slice';
import { useDispatch } from 'react-redux';

type DamageBodyPartSelectionProps = {
  photoSeriesId: string | undefined,
  imageId: string | undefined,
  region: LabelStudioRegion | null,
  setRegion: (region: LabelStudioRegion | null) => void,
  labellingMode: LabellingMode,
  autoSelectBodyPart: boolean
};

type Annotation = {
  id: string,
  label: string
}

const getAnnotationBodyPart = (annotation: Annotation | undefined) : BodyPart | undefined => {
  if (annotation === undefined) return undefined;
  const bodyPartLabel = annotation.label;
  return BodyPart[bodyPartLabel as keyof typeof BodyPart];
};

const DamageBodyPartSelection = (props: DamageBodyPartSelectionProps) => {
  const [selectedAnnotationId, setSelectedAnnotationId] = useState<string>('');
  const [showRelatedDamages, setShowRelatedDamages] = useState<boolean>(false);
  const [selectedDamageId, setSelectedDamageId] = useState<string | null>(null);
  const [showSelectedDamageError, setShowSelectedDamageError] = useState<boolean>(false);
  const [repairDecision, setRepairDecision] = useState<string | RepairDecision>('');

  const { region, setRegion, photoSeriesId, imageId, labellingMode, autoSelectBodyPart } = props;
  const findRelatedDamages = useFindRelatedDamages(photoSeriesId);
  const canEditPhotoSeries = useCanEditCurrentImage(photoSeriesId, imageId, labellingMode);

  const dispatch = useDispatch();

  const {
    annotations: { selectors: annotationsSelectors },
  } = useRedux();
  const photoSeries = useReduxSelector((state) => annotationsSelectors.selectPhotoSeriesByPhotoSeriesId(state, photoSeriesId as string));

  const handleConfirm = useCallback((selectedAreaId: string) => {
    if (selectedAreaId === '' || region == null || !photoSeries || !photoSeriesId) return;
    if (showRelatedDamages && selectedDamageId === null) {
      setShowSelectedDamageError(true);
      return;
    }

    const damageRepairDecision = repairDecision !== ''
      ? repairDecision as RepairDecision
      : null;

    region.setParentID(selectedAreaId);
    region.parentDamageId = selectedDamageId;
    region.repairDecision = damageRepairDecision;

    const damageId = region.id.includes('#')
      ? region.id.split('#')[0].split('.')[1]
      : null;

    if (photoSeries.windshieldRepairReplaceDecisionEnabled) {
      dispatch(updateDamageRepairDecision({
        photoSeriesId,
        damageId,
        parentDamageId: region.parentDamageId,
        repairDecision: damageRepairDecision
      }));
    }

    setRegion(null);
  }, [region, selectedDamageId, setRegion, showRelatedDamages, repairDecision, dispatch, photoSeries, photoSeriesId]);

  const getBodyParts = useCallback(() : Annotation[] => {
    if (region === null) return [];
    const areas: LabelStudioArea[] = Object.values(region.annotation.areas.toPOJO());
    const bodyParts = areas.filter((area) => area.tag !== undefined &&
                                             area.tag.name === 'bodyParts');

    const filteredBodyParts = bodyParts
      .filter((area) => bboxIntersects(region.bboxCoords, area.bboxCoords));

    const annotations = filteredBodyParts.length > 0
      ? filteredBodyParts.map(mapArea)
      : bodyParts.map(mapArea);

    return annotations
      .sort((a, b) => a.label.localeCompare(b.label));
  }, [region]);

  useEffect(() => {
    setShowRelatedDamages(false);
    setSelectedDamageId(null);
    setShowSelectedDamageError(false);
  }, [region, photoSeriesId]);

  // NOTE:  Auto select body part when only one body part is in selection.
  //        Exception 1: We need to show modal if body part has related damages.
  //        Exception 2: Windshield repair replace decision
  useEffect(() => {
    if (!autoSelectBodyPart) return;
    const bodyPartAnnotations = getBodyParts();
    if (bodyPartAnnotations.length !== 1) return;

    const bodyPartAnnotation = bodyPartAnnotations[0];
    const bodyPart = getAnnotationBodyPart(bodyPartAnnotation);
    const relatedDamages = findRelatedDamages({
      labellingMode, bodyPart, damageRegion: region, imageId
    });

    if (relatedDamages.length === 0 && !photoSeries?.windshieldRepairReplaceDecisionEnabled) {
      handleConfirm(bodyPartAnnotation.id);
    } else {
      setSelectedAnnotationId(bodyPartAnnotation.id);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // NOTE: If damage region has parentID, autoselect body part and mark checkbox
  //       'This was visible on other image'
  useEffect(() => {
    if (!region || !region.parentID) return;

    const bodyPartAnnotations = getBodyParts();
    const bodyPartAnnotation = bodyPartAnnotations
      .filter((annotation) => annotation.id === region.parentID)[0];

    if (!bodyPartAnnotation) return;
    setSelectedAnnotationId(bodyPartAnnotation.id);

    if (!region.parentDamageId) return;

    setShowRelatedDamages(true);
    setSelectedDamageId(region.parentDamageId);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!showRelatedDamages) setShowSelectedDamageError(false);
    if (region?.parentDamageId && !showRelatedDamages) region.parentDamageId = null;
  }, [showRelatedDamages, region]);

  // Automatic repair decision selection after user has marked damage or edits damage
  useEffect(() => {
    if (!photoSeries?.windshieldRepairReplaceDecisionEnabled || !region) return;

    const damageType = DamageType[region.labelName as keyof typeof DamageType];
    if (region.repairDecision != null) {
      setRepairDecision(region.repairDecision);
    } else if (damageType == DamageType.Crack || damageType == DamageType.OtherDamage) {
      setRepairDecision(RepairDecision.Replace);
    }
  }, [region, photoSeries]);

  const handleHide = () => {
    if (region == null) return;
    if (region.parentID === null) region.deleteRegion();
    setRegion(null);
  };

  const mapArea = (area: LabelStudioArea) : Annotation => ({
    id: area.id.split('#')[0],
    label: area.labelName
  });

  const getSelectedAnnotationBodyPart = () : BodyPart | undefined => {
    if (selectedAnnotationId === '') return undefined;
    const annotation = getBodyParts().find((annotation) => annotation.id === selectedAnnotationId);
    return getAnnotationBodyPart(annotation);
  };

  const handleSelectedDamageId = (damageId: string | null) : void => {
    setSelectedDamageId(damageId);
    if (!photoSeries?.windshieldRepairReplaceDecisionEnabled) return;

    const relatedDamages = findRelatedDamages({
      labellingMode, bodyPart: getSelectedAnnotationBodyPart(), damageRegion: region, imageId
    });

    const relatedDamage = relatedDamages.find((d) => d.id === damageId);
    if (relatedDamage?.repairDecision) {
      setRepairDecision(relatedDamage?.repairDecision);
    }
  };

  if (!canEditPhotoSeries()) return null;

  return (
    <Modal
      show={region != null}
      onHide={handleHide}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>Body part selection</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Alert variant="warning">
          This dropdown only lists labelled body parts. If you cannot find a suitable body part for the
          current damage please label the body part before labelling the damage.
        </Alert>
        <FloatingLabel controlId="bodyPartSelection" label="Body part selection">
          <Form.Select
            onChange={(event: any) => {
              setSelectedAnnotationId(event.target.value);
              setShowRelatedDamages(false);
              setSelectedDamageId(null);
            }}
            value={selectedAnnotationId}
          >
            {
              selectedAnnotationId === '' && <option value="">-</option>
            }
            {
              getBodyParts().map((annotation) => (
                <option
                  key={annotation.id}
                  value={annotation.id}
                >
                  {annotation.label}
                </option>
              ))
            }
          </Form.Select>
        </FloatingLabel>
        {
          photoSeries?.windshieldRepairReplaceDecisionEnabled && (
            <div className="mt-3">
              <FloatingLabel controlId="repairDecision" label="Damage repairable?">
                <Form.Select
                  onChange={(event: any) => {
                    setRepairDecision(event.target.value);
                  }}
                  value={repairDecision}
                >
                  <option value="">-</option>
                  <option value={RepairDecision.Repair}>Yes</option>
                  <option value={RepairDecision.Replace}>No</option>
                </Form.Select>
              </FloatingLabel>
            </div>
          )
        }
        <div className="mt-3">
          {
            showSelectedDamageError && (
              <Alert variant="danger">
                Select one connected damage or uncheck &apos;This was visible on other image&apos; option.
              </Alert>
            )
          }
          <RelatedDamage
            photoSeriesId={photoSeriesId}
            imageId={imageId}
            bodyPart={getSelectedAnnotationBodyPart()}
            damageRegion={region}
            showRelatedDamages={showRelatedDamages}
            setShowRelatedDamages={setShowRelatedDamages}
            selectedDamageId={selectedDamageId}
            setSelectedDamageId={handleSelectedDamageId}
            labellingMode={labellingMode}
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={() => handleConfirm(selectedAnnotationId)}>Confirm</Button>
      </Modal.Footer>
    </Modal>
  );
};

export { DamageBodyPartSelection };
