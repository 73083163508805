import { useAuth } from 'hooks';
import { useCallback } from 'react';
import { ImageType, LabellingMode, Image } from 'redux/annotations/annotations.type';
import { useRedux, useReduxSelector } from 'redux/store.hooks';
import { useImageNavigation } from './useImageNavigation';

const useCanEditCurrentImage = (photoSeriesId: string | undefined, imageId: string | undefined, labellingMode: LabellingMode) => {
  const { email: currentUserEmail } = useAuth();
  const { annotations: { selectors: annotationsSelectors } } = useRedux();
  const photoSeries = useReduxSelector((state) => annotationsSelectors.selectPhotoSeriesByPhotoSeriesId(state, photoSeriesId as string));
  const { imageIndex } = useImageNavigation();
  const allImages = useReduxSelector((state) => annotationsSelectors.selectImagesByPhotoSeriesId(state, photoSeriesId as string));

  const currentImage =
    useReduxSelector((state) => annotationsSelectors.selectImageByImageIdAndPhotoSeriesId(state, photoSeriesId as string, imageId as string));

  const images = currentImage?.imageType == ImageType.Additional
    ? allImages?.filter((image: Image) => image.imageType === ImageType.Additional)
    : allImages?.filter((image: Image) => image.imageType !== ImageType.Additional);

  const imageNotAnalaysed = !images || !images[imageIndex] || !images[imageIndex].analysedAt;

  return useCallback(() => {
    if (photoSeries === undefined) return false;
    if (imageNotAnalaysed) return false;
    if (labellingMode === LabellingMode.ModelTraining) return true;

    return photoSeries.analysisAssignedToUser === currentUserEmail;
  }, [photoSeries, currentUserEmail, labellingMode, imageNotAnalaysed]);
};

export { useCanEditCurrentImage };
