import { useMemo, useCallback, useState, useEffect } from 'react';
import styled from 'styled-components';

import Tab from 'components/shared/tab';

import { useTitle } from 'hooks';
import { ButtonsGroup } from 'components/layouts';
import { PageWrapper } from 'components/containers';
import { Annotation } from './components/annotation';
import { useListView } from './hooks/useListView';
import { PagedPhotoseries, SortDirection, SortColumn, QueryType, Image, ImageType } from 'redux/annotations/annotations.type';
import { MessageUpdate } from 'components/shared/message-update/message-update.component';
import { SortItem, SortSelection } from './components/sort-selection';
import { AssignedToCurrentUserFilter } from './components/assigned-to-current-user-filter';
import { NeedReviewPolling } from 'components/pages/annotations/components/need-review-polling';
import { SearchPhrase } from './components/search-phrase';
import { AutoSubmitCheckbox } from './components/auto-submit-checkbox';
import { ListViewLoadMore, PhotoSeriesListViewSkeleton } from 'components/shared';

const WrapperRow2 = styled.div`
  border: 0.0625rem solid #e2e2e2;
  box-sizing: border-box;
  box-shadow: 0rem 0.25rem 0.25rem rgba(0, 0, 0, 0.04);
  border-radius: 0.375rem;
  padding: 1.8125rem 1.9375rem;
  margin: 0.625rem auto;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;

  & .options {
    width: 43.75rem;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    & .filter {
      margin-right: 1rem;
      width: 14rem;
    }

    & .sort {
      width: 12rem;
    }

    & .search {
      width: 12.5rem;
      margin-right: 1rem;
    }
  }
`;

const UrgentSortItems: SortItem[] = [
  { label: 'Urgent last', sortColumn: SortColumn.CompletedAt, sortDirection: SortDirection.Desc },
  { label: 'Urgent first', sortColumn: SortColumn.CompletedAt, sortDirection: SortDirection.Asc },
];

const ReviewedSortItems: SortItem[] = [
  { label: 'Reviewed at closest to now', sortColumn: SortColumn.AnalysisConfirmedAt, sortDirection: SortDirection.Desc },
  { label: 'Reviewed at farthest from now', sortColumn: SortColumn.AnalysisConfirmedAt, sortDirection: SortDirection.Asc },
];

function Labelling() {
  useTitle('Labelling');
  const {
    isLoading,
    error,
    pagedPhotoSeries,
    queryType,
    pagedQuery,
    setPagedQuery,
    setQueryType,
    assignedToCurrentUser,
    setAssignedToCurrentUser,
    searchPhrase,
    setSearchPhrase,
    notConfirmedForRetraining,
    setNotConfirmedForRetraining,
    hasRepairDecision,
    setHasRepairDecision,
  } = useListView();

  const [sortItems, setSortItems] = useState<SortItem[]>([]);

  const currentPage = (pagedPhotoSeries as PagedPhotoseries).page;
  const totalPage = (pagedPhotoSeries as PagedPhotoseries).pageCount;
  const pageSize = (pagedPhotoSeries as PagedPhotoseries).pageSize;
  const rowCount = (pagedPhotoSeries as PagedPhotoseries).rowCount;

  const shouldLoadMore = totalPage > currentPage;
  const isDisabled = currentPage >= totalPage;

  const renderAnnotations = useMemo(() => {
    if (!(pagedPhotoSeries as PagedPhotoseries)?.results?.length) return null;

    const annotationsNode = (pagedPhotoSeries as PagedPhotoseries).results.map((result) => (
      <Annotation
        key={result.photoSeriesId}
        vin={result.vehicle.vin}
        images={result.images.filter((image: Image) => image.imageType !== ImageType.Additional)}
        queryType={queryType}
        photoSeriesId={result.photoSeriesId}
        completedAt={result.completedAt}
        analysisConfirmedAt={result.analysisConfirmedAt}
        analysisAssignedAt={result.analysisAssignedAt}
        registrationNo={result.vehicle.registrationNo}
        analysisAssignedToUser={result.analysisAssignedToUser}
      />
    ));

    return annotationsNode;
  }, [pagedPhotoSeries, queryType]);

  const renderLoader = useMemo(() => {
    if (!isLoading) return;

    let nextLoadCount = 5;

    if (rowCount) {
      const remainingRows = rowCount - currentPage * pageSize;
      if (remainingRows > 0) {
        nextLoadCount = Math.min(remainingRows, pageSize);
      }
    }

    return <PhotoSeriesListViewSkeleton quantity={nextLoadCount} />;
  }, [isLoading, currentPage, rowCount, pageSize]);

  useEffect(() => {
    switch (queryType) {
      case QueryType.ReviewNeeded:
        setSortItems(UrgentSortItems);
        break;
      case QueryType.Reviewed:
        setSortItems(ReviewedSortItems);
        break;
      case QueryType.InReview:
        setSortItems(UrgentSortItems);
        break;
      default:
        break;
    }
  }, [queryType, setSortItems]);

  return (
    <>
      <PageWrapper pageTitle={<NeedReviewPolling title="Labelling" setPagedQuery={setPagedQuery} />}>
        <Tab.Root>
          <Header>
            <ButtonsGroup>
              <Tab.TabControl
                shouldSelectOnMount
                disabled={queryType === QueryType.ReviewNeeded}
                onControl={useCallback(() => {
                  setPagedQuery({
                    ...pagedQuery,
                    page: 1,
                    sortColumn: SortColumn.CompletedAt,
                    sortDirection: SortDirection.Asc,
                  });
                  setQueryType(QueryType.ReviewNeeded);
                }, [setQueryType, setPagedQuery, pagedQuery])}
              >
                Need review
              </Tab.TabControl>
              <Tab.TabControl
                disabled={queryType === QueryType.InReview}
                onControl={useCallback(() => {
                  setPagedQuery({
                    ...pagedQuery,
                    page: 1,
                    sortColumn: SortColumn.CompletedAt,
                    sortDirection: SortDirection.Asc,
                  });
                  setQueryType(QueryType.InReview);
                }, [setQueryType, setPagedQuery, pagedQuery])}
              >
                In review
              </Tab.TabControl>
              <Tab.TabControl
                disabled={queryType === QueryType.Reviewed}
                onControl={useCallback(() => {
                  setPagedQuery({
                    ...pagedQuery,
                    page: 1,
                    sortColumn: SortColumn.AnalysisConfirmedAt,
                    sortDirection: SortDirection.Desc,
                  });
                  setQueryType(QueryType.Reviewed);
                }, [setQueryType, setPagedQuery, pagedQuery])}
              >
                Reviewed
              </Tab.TabControl>
            </ButtonsGroup>
            <div className="options">
              {queryType === QueryType.InReview && (
                <div className="filter">
                  <AssignedToCurrentUserFilter
                    assignedToCurrentUser={assignedToCurrentUser}
                    setAssignedToCurrentUser={(value: boolean) => {
                      setPagedQuery({
                        ...pagedQuery,
                        page: 1,
                      });
                      setAssignedToCurrentUser(value);
                    }}
                  />
                </div>
              )}
              {queryType === QueryType.Reviewed && (
                <div className="filter">
                  <AutoSubmitCheckbox
                    value={notConfirmedForRetraining}
                    setValue={(value: boolean) => {
                      setPagedQuery({
                        ...pagedQuery,
                        page: 1,
                      });
                      setNotConfirmedForRetraining(value);
                    }}
                    label="Not confirmed for retraining"
                  />
                  <AutoSubmitCheckbox
                    value={hasRepairDecision}
                    setValue={(value: boolean) => {
                      setPagedQuery({
                        ...pagedQuery,
                        page: 1,
                      });
                      setHasRepairDecision(value);
                    }}
                    label="Has repair decision"
                  />
                </div>
              )}
              <SearchPhrase
                className="search"
                searchPhrase={searchPhrase}
                setSearchPhrase={(value: string) => {
                  setPagedQuery({
                    ...pagedQuery,
                    page: 1,
                  });
                  setSearchPhrase(value);
                }}
              />
              <div className="sort">
                <SortSelection sortItems={sortItems} pagedQuery={pagedQuery} setPagedQuery={setPagedQuery} />
              </div>
            </div>
          </Header>
          <Tab.TabPanel>
            <WrapperRow2>
              {error && <MessageUpdate type="danger">{error}</MessageUpdate>}
              {renderAnnotations}
              {renderLoader}
            </WrapperRow2>
          </Tab.TabPanel>
        </Tab.Root>
        <ListViewLoadMore
          results={rowCount}
          loadMoreButtonDisabled={isDisabled}
          showLoadMoreButton={shouldLoadMore}
          handleLoadMoreOnClick={() => {
            setPagedQuery({
              ...pagedQuery,
              page: (pagedQuery.page ?? 1) + 1,
            });
          }}
        />
      </PageWrapper>
    </>
  );
}

export default Labelling;
